.popup {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  z-index: 1;
}

@media only screen and (min-width: 600px) {
  .popup_inner_card {
    position: absolute;
    left: 20%;
    right: 0%;
    top: 15%;
    bottom: 5%;
    margin: auto;
    background: white;
    overflow-y: auto;
    overflow-x: hidden;
  }
}
@media only screen and (max-width: 600px) {
  .popup_inner_card {
    position: absolute;
    left: 5%;
    right: 5%;
    top: 10%;
    bottom: 5%;
    margin: auto;
    background: white;
    overflow-y: auto;
    overflow-x: hidden;
  }
}

.close-popup {
  color: black;
  cursor: pointer;
  margin-right: 15px;
}
.row-popup {
  margin-left: 10px !important;
  margin-right: 0px !important;
}

.react-datepicker__input-container > input {
  border: none;
}
.not-form-group {
  margin-bottom: 1rem;
}
.not-form {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

input.inputnumber {
  width: 80px !important;
}

.popup {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  z-index: 1;
}

@media only screen and (min-width: 600px) {
  .popup_inner {
    position: absolute;
    left: 25%;
    right: 18%;
    top: 10%;
    bottom: 5%;
    margin: auto;
    background: white;
    overflow-y: auto;
    overflow-x: hidden;
  }
}

@media only screen and (max-width: 600px) {
  .popup_inner {
    position: absolute;
    left: 5%;
    right: 5%;
    top: 10%;
    bottom: 5%;
    margin: auto;
    background: white;
    overflow-y: auto;
    overflow-x: hidden;
  }
}

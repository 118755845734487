/* The device with borders */
.smartphone {
  position: relative;
  width: 299px;
  height: 596px;
  margin: auto;
  border: 16px black solid;
  border-top-width: 60px;
  border-bottom-width: 60px;
  border-radius: 36px;
}

/* The horizontal line on the top of the device */
.smartphone:before {
  content: '';
  display: block;
  width: 60px;
  height: 5px;
  position: absolute;
  top: -30px;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #333;
  border-radius: 10px;
}

/* The circle on the bottom of the device */
.smartphone:after {
  content: '';
  display: block;
  width: 35px;
  height: 35px;
  position: absolute;
  left: 50%;
  bottom: -65px;
  transform: translate(-50%, -50%);
  background: #333;
  border-radius: 50%;
}
/* For width 400px and larger: */
@media all and (min-width: 200px) and (max-width: 500px) {
  .smartphone {
    position: relative;
    width: 262px;
    height: 634px;
    margin: auto;
    border: 16px black solid;
    border-top-width: 60px;
    border-bottom-width: 60px;
    border-radius: 36px;
  }
}

/* The screen (or content) of the device */
.smartphone .content {
  /* width: 360px;
  height: 640px; */
  background: white;
}
.card-img-top {
  margin-top: 3px;
  border-radius: 50% !important;
  width: 100px !important;
  height: 100px;
}
.contentphone {
  height: 520px;
  overflow-y: scroll;
}
.cadre-phone {
  padding: 7px;
}
.space-phone {
  padding-left: 40px;
}
